import today from './today';
import my from './my';
import jelly from './jelly';
import alliance from './alliance';
import benefit from './benefit';
import product from './product';

//기본 레이아웃값
const defaultLayoutData = {
  // 헤더값
  headerType: 'normal', // 헤더 타입 (normal, logo)
  title: 'Main title', //  헤더 타이틀값
  isSticky: false, // 헤더가 항상 위에 존재하는지 유무
  btnText: '', // 텍스트 버튼 값 + 유무

  // headerType이 normal일 때만 동작
  isViewAdd: false, // 더하기 버튼 유무
  isViewAppClose: false, // 닫기 버튼 유무
  isViewInfo: false, // 정보 버튼 유무
  isViewMore: false, // 더보기 버튼 유무

  //  headerType이 logo일 때만 동작
  isViewSearch: false, // 검색 버튼 유무
  isViewAlarm: false, // 알람 버튼 유무
  hasAlarm: false, // 알람버튼이 true 일 때, 알람 유무

  placeholder: '메뉴',
  isClear: true,
  auto: true,
  isViewSearch: true,
};

const totalLayoutData = {
  ...defaultLayoutData,
};

export default { ...today, ...my, ...alliance, ...jelly, ...benefit, ...product, totalLayoutData };
